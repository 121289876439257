<template>
  <div class="flex-grow-1">
    <AdminTitle title="Open Job Ads">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">

          <v-btn
            v-if="$store.getters['pricing/withActiveSub']"
            :to="{ name: 'Job' }"
            color="accent gradient"
            class="d-none d-sm-flex"
          >
            Create Job
          </v-btn>
          
          <v-btn
            v-if="$store.getters['pricing/withActiveSub']"
            :to="{ name: 'Job' }"
            class="d-flex d-sm-none"
            color="accent gradient"
            depressed
            small
            fab
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <MyJobFilter @changed="updateOrder"/>
        </div>
      </template>
    </AdminTitle>

    <!-- Filters -->
    <v-row>
      <v-col cols="12" class="jobs-section">
        <preloader v-if="status.getting" />
        
        <custom-alert v-if="!ordered.length && !status.getting">
          No job found
        </custom-alert>
        
        <div v-if="ordered.length && !status.getting">
          <!-- Job Card -->
            <job-card
              v-for="job in ordered" 
              :key="job.id"
              :job="job"
            />
          <!-- End Job Card -->
        </div>
      </v-col>
    </v-row>
    <!-- End Filters -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MyJobFilter from '@/components/dashboard/job/MyJobFilter'
import JobCard from '../JobCard'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'MyJobs',

  metaInfo: {
    title: 'My Jobs'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy: 'created',
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MyJobFilter,
    JobCard
  },
   
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      jobs: (state) => state.jobs.jobs,
      status: state => state.jobs.status,
      applicantStatus: (state) => state.apply.status,
    }),

    ordered: function () {
      let jobs = this.jobs.filter(job => job.status !== 'Closed')

      // ORDER BY CREATED
      if (this.orderBy == 'created') {
        jobs = _.orderBy(jobs, 'createdAt', 'desc')
      }

      // CREATED AT DATE
      if (this.orderBy == 'start_date') {
        jobs = _.sortBy(jobs, [function (job) {
          let range = job.contractDates[0]
          return moment(range[0]).valueOf()
        }])
      }

      // ORDER BY PRIVATE
      if (this.orderBy == 'private') {
        jobs = jobs.filter(job => job.private)
      }
      
      // ORDER BY PRIVATE
      if (this.orderBy == 'public') {
        jobs = jobs.filter(job => !job.private)
      }

      return jobs
    }
  },


  methods: {
    ...mapActions("jobs", [
      'getAllJobs',
    ]),

    ...mapActions("apply", [
      'allApplicants'
    ]),

    updateOrder(order) {
      this.orderBy = order
    },
  },
  
  created() {
    this.allApplicants()
    this.getAllJobs()
    this.$store.commit('job/duplicatingState', null)
  }
}
</script>